import React, {useEffect, useState} from 'react';
import { Button, Col, Input, Row, Spin} from 'antd';
import './content-header.scss';
import Filters from './Filters';
import ExportSavedContentModal from '../../../../ExportSavedContentModal';
import './ContentHeader.scss';
import { axiosDefaultClient } from '../../../../../settings/axios.settings';

const ContetHeader = ({ filters, setFilters, setKeyword, onKeyWordChange }) => {

    const [openExportSavedContentModal, setOpenExportSavedContentModal] = useState(false);
    const [lastReportData, setLastReportData] = useState({message: null, state: null});

    const openModal = () => {
        setOpenExportSavedContentModal(true);
    };

    useEffect(() => {
        axiosDefaultClient
            .post(`/api/trainer/request-saved-content-report-status`, {})
            .then(response => {
                const payload = response?.data?.payload;
                setLastReportData(payload);
            })
            .catch(e => {
                console.log(e);
            });
    }, []);

    useEffect(() => {
        let interval = null
        if (lastReportData?.state === 0) {
            interval = setInterval(async () => {
                axiosDefaultClient
                .post(`/api/trainer/request-saved-content-report-status`, {})
                .then(response => {
                    const payload = response?.data?.payload;
                    setLastReportData(payload);
                })
                .catch(e => {
                    console.log(e);
                });
            }, 5000);
        } else {
            clearInterval(interval)
        }
        return () => {
            clearInterval(interval)
        }
    }, [lastReportData])

    return (
        <>
          <Row className="content-header" justify="start">
            <Col span={20} align="start" style={{display: 'flex', flexDirection: 'row'}}>
                <h3>Filter by user/user group and/or saved content type: </h3>
                <Input
                    suffix={
                        <Filters filters={filters} setFilters={setFilters} />
                    }
                    className="search-input"
                    type={'submit'}
                />
            </Col>
            <Col span={4} align="start" style={{display: 'flex', flexDirection: 'row'}}>
                <Button
                    onClick={openModal}
                    className="export-button"
                    type="primary"
                >
                    {window.__lang('export_excel')}
                </Button>
            </Col>
        </Row>
        <Row>
            {lastReportData?.message && 
            <div>
                {lastReportData?.state === 0 && (
                        <div className='warning'>
                            <Spin/>
                            <span dangerouslySetInnerHTML={{__html: lastReportData?.message}} style={{marginLeft: 10}}/>
                        </div>
                    )}
                    {lastReportData?.state === 1 && (
                        <div className='success'>
                             <span dangerouslySetInnerHTML={{__html: lastReportData?.message}}/>
                        </div>
                    )}
                    {lastReportData?.state === 2 && (
                        <div className='error'>
                            <span dangerouslySetInnerHTML={{__html: lastReportData?.message}} />
                        </div>
                    )}
            </div>}
        </Row>
        <ExportSavedContentModal
                openExportSavedContentModal={openExportSavedContentModal}
                setOpenExportSavedContentModal={setOpenExportSavedContentModal}
                setLastReportData={setLastReportData}
        />
        </>
      
    );
};

export default ContetHeader;
