const rolesAndPermisions = {
    seeUsers: 'U1',
    setUpAdminUsers: 'U2',
    setUpLearnersTrainersHrmanagersUsersManualViaCsv: 'U3',
    editUsersManualViaCsv: 'U4',
    disableUserAccountManualViaCsv: 'U5',
    deleteUserAccountManualViaCsv: 'U6',
    setUpPadlockedDemoUsers: 'U7',
    setUpUrlDemoUsers: 'U8',
    setUpTrainersManualViaCsv: 'U9',
    editTrainersManualViaCsv: 'U10',
    disableTrainersManualViaCsv: 'U11',
    canResendWelcomeEmail: 'U12',
    setUpUserGroupsManual: 'UG1',
    setUpUserGroupsViaCsv: 'UG2',
    editUserGroupsManual: 'UG3',
    editUserGroupsViaCsv: 'UG4',
    deleteUserGroupsManual: 'UG5',
    deleteUserGroupsViaCsv: 'UG6',
    manageAdditionalUserData: 'UG7',
    canEditAllUserGroupsOnTheSite: 'UG10',
    accessReportingDataForAllUsersUserGroupsOnSite: 'R1',
    accessReportingDataForAllLinkedUsersUserGroups: 'R2',
    accessReportingDataForAllLinkedUsersBetweenStartArrivalDateAndDepartureDatePlusOneWeek:
        'R3',
    editReportingData: 'R4',
    deleteReportingData: 'R5',
    createQuestionnaires: 'Q1',
    editQuestionnaires: 'Q2',
    deleteQuestionnaires: 'Q3',
    viewQuestionnaireResponsesForAllSiteUsers: 'Q4',
    viewQuestionnaireResponsesForLinkedUsersUserGroups: 'Q5',
    editQuestionnaireResponses: 'Q6',
    deleteQuestionnaireResponses: 'Q7',
    allocateAllocatableQuestionnairesToAnyUserUserGroup: 'Q8',
    allocateAllocatableQuestionnairesToLinkedUsersUserGroups: 'Q9',
    createTutorialTemplates: 'T1',
    editTutorialTemplates: 'T2',
    deleteTutorialTemplates: 'T3',
    completeTutorialsForAllUsers: 'T4',
    // complete-tutorials-for-linked-users	T5
    // assign-completed-tutorials-to-any-user/user-group	T6
    // assign-completed-tutorials-to-linked-user/user-groups	T7
    // assign-tutorials-to-be-completed-to-any-user/user-group	T8
    // assign-tutorials-to-be-completed-to-linked-user/user-groups	T9
    // edit-any-completed-tutorials	T10
    // edit-tutorials-completed-by-linked-users	T11
    // edit-tutorials-completed-by-themselves	T12
    // create-class-record-templates	CR1
    // create-class-record-templates-for-own-use	CR2
    // delete-all-class-record-templates	CR3
    // delete-class-record-templates-created-for-own-use	CR4
    // edit-class-record-templates	CR5
    // edit-class-record-templates-created-for-own-use	CR6
    // complete,-allocate-and-unallocate-class-records-to-any-user/user-group	CR7
    // complete,-allocate-and-unallocate-class-records-to-linked-user/user-group	CR8
    // edit-all-completed-class-records	CR9
    // edit-class-records-completed-by-them	CR10
    // delete-all-completed-unassigned-class-records	CR11
    // delete-unassigned-class-records-completed-by-them	CR12
    // archive-all-class-records	CR13
    // archive-class-records-completed-by-them	CR14
    // delete-completed,-assigned-class-records-for-any-user/user-group	CR15
    // delete-completed,-assigned-class-records-for-linked-user/user-group	CR16
    createContentForTheSite: 'CA1',
    // allocate-all-trainer-authored-content-to-any-user-on-the-site	CA2
    allocateTheirOwnOrSharedTrainerAuthoredContentToLinkedUsersUserGroupsOnTheSite:
        'CA3',
    editDeleteAllTrainerAuthoredContentOnTheSite: 'CA4',
    editDeleteTheirOwnTrainerAuthoredContentOnTheSite: 'CA5',
    // copy-then-edit-all-trainer-authored-content-on-the-site	CA6
    // copy-edit-any-shared-trainer-authored-content-on-the-site	CA7
    seeAllContentAvailableOnSite: 'C1',
    seeAllContentAllocatedToLinkedUsersUserGroups: 'C2',
    assignModules: 'C3',
    seeAllTrainerAllocatableLibraryBundlesAvailableOnSite: 'C4',
    seeAllTrainerAllocatableHybridLearningPathwayAvailableOnSite: 'C5',
    // see-all-content-in-student-database-via-search	C6
    // see-all-content-in-trainer-database-via-search	C7
    // see-all-content-in-my-content-database-via-search	C8
    // see-all-content-in-shared-content-other-trainer-database-via-search	C9
    createCopyEditAndDeleteALibraryBundle: 'C10',
    createCopyEditAndDeleteASiteAuthoredLibraryBundleAndMakeAvailableOnTheirSite:
        'C11',
    allocateUnallocateALibraryBundleAvailableOnTheSiteYoAnyUserUserGroup: 'C12',
    // allocate-a-library-bundle-to-any-linked-user/user-group-on-the-site-and-unallocate-a-bundle-they-have-allocated-it	C13
    // create-hybrid-learning-pathway	C14
    // edit-hybrid-learning-pathways	C15
    canUnassignAnyTrainerAllocatablePathwayExceptOthersAllocatedByTrainers:	'C16',
    canUnassignTrainerAllocatableOrSelfAuthoredPathwayTheyAssigned:	'C17',
    canUnassignATrainerAllocatableOrSelfAuthoredPathwayAssignedInAnyWay: 'C27',
    canHideAnyTypeOfPathwayAssignedViaAGroupFromAnIndividualLearnersAccount: 'C28',
    // allocate-hybrid-learning-pathway-to-any-user/user-group-on-site	C18
    AssignAllocatblePathwaysToLinkedUserOrUserGroupOnSite: 'C19',
    // customise-hybrid-learning-pathways-(add-sections-and-modules)	C20
    // set-weeks-for-hybrid-learning-pathways	C21
    // set-image-for-hybrid-pathway-sections	C22
    seeUserGroups: 'UG8',
    seeAllUserGroupsOnTheSite: 'UG11',
    // see-learning-pathways	C23
    // set-up-sites	S1
    // edit-sites	S2
    seeSites: 'S3',
    // delete-sites	S4
    CanAllocateContent: 'C3',
    seeAllContentInStudentDatabaseViaSearch: 'C6',
    seeAllContentInTrainerDatabaseViaSearch: 'C7',
    seeAllContentInMyContentDatabaseViaSearch: 'C8',
    seeAllContentInSharedContentOtherTrainerDatabaseViaSearch: 'C9',
    createHybridLearningPathway: 'C14',
    AssignAllocatblePathwaysToAnyUserUserGroupOnSite: 'C18',
    CanAllocateHybridLearningPathwayToALinkedUserUserGroupOnSite: 'C19',
    CanCustomiseHybridLearningPathwaysAddSectionsAndModules: 'C20',
    CanSetImageForHybridPathwaySections: 'C22',
    seeActivities: 'A1',
    editActivities: 'A2',
    deleteActivities: 'A3',
};
export default rolesAndPermisions;
