import React, { useState, useEffect } from 'react';
import { Col, Row, Modal, Select} from 'antd';
import { axiosDefaultClient } from '../../settings/axios.settings';
import userGroupsAction from '../../redux/actions/userGroup.actions';
import savedContentActions from '../../redux/actions/saveContent.actions';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveDecryptedUserFromLocalStorage } from '../../utils/common';
import './ExportSavedContentModal.scss';
const { Option } = Select;

const ExportSavedContentModal = ({
    openExportSavedContentModal,
    setOpenExportSavedContentModal,
    setLastReportData,
}) => {
    const user = retrieveDecryptedUserFromLocalStorage();
    const dispatch = useDispatch();
    const [values, setValues] = useState({
        groupID: null,
        activityID: null,
    });
    const userGroups = useSelector(state => state.userGroups.userGroups);
    const trainerActivities = useSelector(
        (state) => state.savedContent.activities,
    );
    
    useEffect(() => {
        dispatch(
            userGroupsAction.fetchAllUserGroups(null, user.siteID, 'select'),
        );
        dispatch(
            savedContentActions.getTrainerActivityContentActivities(
                user.userID,
            ),
        );
    }, []);

    const handleCancel = () => {
        setOpenExportSavedContentModal(false);
    };

    const exportExcel = () => {
        let body = {
            userGroupID: values?.groupID,
            activityID: values?.activityID
        };
       
        axiosDefaultClient
            .post(`/api/trainer/request-saved-content-report`, body)
            .then(response => {
                const payload = response?.data?.payload;
                setLastReportData(payload);
                setOpenExportSavedContentModal(false);
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <Modal
            title={window.__lang(`export_saved_content`)}
            open={openExportSavedContentModal}
            onOk={exportExcel}
            onCancel={handleCancel}
            okText={window.__lang('Export')}
            destroyOnClose
        >
           <>
                <Row gutter={8} className="filters-container">
                    <Col className="col" span={24}>
                        <div>{window.__lang("group")}</div>
                        <Select
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={window.__lang('Group')}
                            className="select"
                            value={values.groupID}
                            onChange={(value) =>
                                setValues((prev) => ({
                                    ...prev,
                                    groupID: value,
                                }))
                            }
                        >
                            {userGroups?.data?.map((group, index) => (
                                <Option value={group.userGroupID} key={index}>
                                    {group.userGroup}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col className="col" span={24}>
                        <div>{window.__lang("activity")}</div>
                        <Select
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={window.__lang('Activity')}
                            className="select"
                            value={values.activityID}
                            onChange={(value) =>
                                setValues((prev) => ({
                                    ...prev,
                                    activityID: value,
                                }))
                            }
                        >
                            {trainerActivities?.map((activity, index) => (
                                <Option
                                    value={activity.activityID}
                                    key={index}
                                >
                                    {activity.activityName}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
            </>
        </Modal>
    );
};
export default ExportSavedContentModal;