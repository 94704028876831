import React, { createContext } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Button } from 'antd';
import {
    FacebookFilled,
    TwitterCircleFilled,
    InstagramFilled,
    YoutubeFilled,
    LinkedinFilled,
} from '@ant-design/icons';
import version from '../../../utils/version'
import { retrieveDecryptedUserFromLocalStorage } from '../../../utils/common';

const { Footer } = Layout;
const copyright = window.siteInfo?.copyright ?? 'eFlex International';

const SiteFooter = () => {
    const date = new Date();
    const year = date.getFullYear();
    const site = window?.siteInfo;
    const user = retrieveDecryptedUserFromLocalStorage();
    const messagesLink = user.role === "trainer" ? "/trainer/messages" : "/admin/messages";
    
    return (
        <Footer
            style={{
                textAlign: 'center',
                backgroundColor: 'var(--primary-color, #5371d3)',
                color: 'white',
            }}
        >
            <Link to={messagesLink}>
                <span style={{ color: 'white', padding: 10, fontSize: 12 }}>
                    {window.__lang('contact')}
                </span>
            </Link>
            <Link to="/">
                <span style={{ color: 'white', padding: 10, fontSize: 12 }}>
                    {window.__lang('help')}
                </span>
            </Link>

            {site ? (
                <div style={{ paddingTop: 20 }}>
                    {site?.facebook !== null && (
                        <FacebookFilled
                            style={{ color: 'white', padding: 10 }}
                            onClick={() => {
                                //   site?.facebook ?
                                window.open(site?.facebook, '_blank');
                                //   : window.open(
                                //   'https://www.facebook.com/eflextraining',
                                //   '_blank', );
                            }}
                        />
                    )}
                    {site?.twitter !== null && (
                        <TwitterCircleFilled
                            style={{ color: 'white', padding: 10 }}
                            onClick={() => {
                                // site?.twitter ?
                                window.open(site?.twitter, '_blank');
                                // : window.open(
                                //     'https://twitter.com/eflextraining',
                                //     '_blank',)
                            }}
                        />
                    )}
                    {site?.instagram !== null && (
                        <InstagramFilled
                            style={{ color: 'white', padding: 10 }}
                            onClick={() => {
                                //  ?
                                window.open(site?.instagram, '_blank');
                                // :
                                // window.open('/', '_blank');
                            }}
                        />
                    )}
                    {site?.youTube !== null && (
                        <YoutubeFilled
                            style={{ color: 'white', padding: 10 }}
                            onClick={() => {
                                //  site?.youTube ?
                                window.open(site?.youTube, '_blank');
                                //  :
                                //  window.open('/', '_blank');
                            }}
                        />
                    )}
                    {site?.linkedin !== null && (
                        <LinkedinFilled
                            style={{ color: 'white', padding: 10 }}
                            onClick={() => {
                                window.open(site?.linkedin, '_blank');
                            }}
                        />
                    )}
                </div>
            ) : (
                ''
            )}
            <div style={{ textAlign: 'center', fontSize: 10, padding: '30px 10px 10px 10px '}}>
                {window.__lang('copyright')} © {year} {copyright}
            </div>
            <div>
                <Button>{window.__lang('version')} {version}</Button>
            </div>
        </Footer>
    );
};
export default SiteFooter;
