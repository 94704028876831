import messagesConstants from "../constants/messages.constants";
import {axiosDefaultClient} from "../../settings/axios.settings";
import {notification} from 'antd';

const client = axiosDefaultClient;

const createMessageSubmitted = (
    values, form
) => {
    return dispatch => {
        dispatch({
            type: messagesConstants.CREATE_MESSAGES_REQUEST,
        });

        const url = '/api/threads/create-new'
        client
            .post(url, values)
            .then(response => {
                form.resetFields();
                dispatch({
                    type: messagesConstants.CREATE_MESSAGES_SUCCESS
                });
                notification.success({
                    message: 'success',
                    description: 'message was sent'
                })
                window.location.reload();
            })
            .catch(error => {
                dispatch({
                    type: messagesConstants.CREATE_MESSAGES_REJECTED,
                    payload: error
                });
                notification['error']({
                    message: 'error',
                    description: error.data.message
                })
            })
    }
}

const fetchSentThreads = (keyword, dateFilter) => {
    return async dispatch => {
        let url = '/api/threads/sent?'
        if (keyword) url += `&search=${keyword}`
        if (dateFilter) url = url + `?date_filter=${dateFilter}`;

        dateFilter || keyword ?
            dispatch({
                type: messagesConstants.FETCH_FILTERED_SENT_MESSAGES_REQUEST
            }) :
            dispatch({
                type: messagesConstants.FETCH_SENT_THREADS_REQUEST
            })

        await client
            .get(url)
            .then(response => {
                dateFilter || keyword ?
                    dispatch({
                        type: messagesConstants.FETCH_FILTERED_SENT_MESSAGES_SUCCESS,
                        payload: response.data.threads
                    }) :
                    dispatch({
                        type: messagesConstants.FETCH_SENT_THREADS_SUCCESS,
                        payload: response.data.threads
                    })
                dispatch({
                    type: messagesConstants.GET_TOTAL_SENT_THREADS,
                    payload: response.data.threads_total
                })
            })
            .catch(error => {
                dateFilter || keyword ?
                    dispatch({
                        type: messagesConstants.FETCH_FILTERED_SENT_MESSAGES_REJECTED,
                        payload: error.data.message
                    }) :
                    dispatch({
                        type: messagesConstants.FETCH_SENT_THREADS_REJECTED,
                        payload: error.data.message
                    });
            });
    };
}

const conversationReplaySubmitted = (
    threadID,
    // message
    data,
    resetForm
) => {
    return dispatch => {
        dispatch({
            type: messagesConstants.CONVERSATION_REPLAY_REQUEST,
        });

        const url = `/api/threads/${threadID}/conversation/reply`
        client
            .post(url, data)
            .then(response => {
                resetForm();
                dispatch({
                    type: messagesConstants.CONVERSATION_REPLAY_SUCCESS,
                    payload: response.data.messages
                });
                notification.success({
                    message: 'success',
                    description: 'message was sent'
                })
            })
            .catch(error => {
                dispatch({
                    type: messagesConstants.CONVERSATION_REPLAY_REJECTED,
                    payload: error
                });
                notification['error']({
                    message: 'error',
                    description: error.data.message
                });
            });
    }
}

const fetchInboxThread = (keyword, dateFilter) => {
    return async dispatch => {
        let url = '/api/threads?source=inbox'
        if (keyword) url += `&search=${keyword}`
        if (dateFilter) url += `&date_filter=${dateFilter}`

        dateFilter || keyword ?
            dispatch({
                type: messagesConstants.FETCH_FILTERED_INBOX_MESSAGES_REQUEST
            }) :
            dispatch({
                type: messagesConstants.FETCH_INBOX_THREADS_REQUEST
            })

        await client
            .get(url)
            .then(response => {
                dateFilter || keyword ?
                    dispatch({
                        type: messagesConstants.FETCH_FILTERED_INBOX_MESSAGES_SUCCESS,
                        payload: response.data.threads
                    }) :
                    dispatch({
                        type: messagesConstants.FETCH_INBOX_THREADS_SUCCESS,
                        payload: response.data.threads
                    });
                dispatch({
                    type: messagesConstants.GET_TOTAL_INBOX_THREADS,
                    payload: response.data.threads_total,
                })
            })
            .catch(error => {
                dateFilter || keyword ?
                    dispatch({
                        type: messagesConstants.FETCH_FILTERED_INBOX_MESSAGES_REJECTED,
                        payload: error.data.message
                    }) :
                    dispatch({
                        type: messagesConstants.FETCH_INBOX_THREADS_REJECTED,
                        payload: error.data.message
                    });
            });
    };
}

const fetchConversations = (threadID) => {
    return async dispatch => {
        let url = `/api/threads/${threadID}/conversation`
        // if (dateFilter) url = url + `date_filter=${dateFilter}`;

        dispatch({
            type: messagesConstants.FETCH_CONVERSATION_REQUEST
        })

        await client
            .get(url)
            .then(response => {
                dispatch({
                    type: messagesConstants.FETCH_CONVERSATION_SUCCESS,
                    payload: response.data.messages.reverse()
                });
            })
            .catch(error => {
                dispatch({
                    type: messagesConstants.FETCH_CONVERSATION_REJECTED,
                    payload: error.data.message
                });
            });
    };
}

const fetchUserTrainers = (userId) => {
    return dispatch => {
        // let url = `/api/users/${userId}/trainers?page=${page?.toString()}&per_page=${pageSize}`;
        let url = `/api/users/${userId}/trainers`;

        dispatch({
            type: messagesConstants.FETCH_USERS_LINKED_TRAINERS_REQUEST,
        });
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: messagesConstants.FETCH_USERS_LINKED_TRAINERS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: messagesConstants.FETCH_USERS_LINKED_TRAINERS_REJECTED,
                    payload: error,
                });
            });
    };
};

const fetchTrainers = (siteID, perPage) => {
    return dispatch => {
        let url = `/api/users?site=${siteID}&role=trainer&source=message-center`;
        if (perPage) url = url + `&per_page=${perPage}`;
        dispatch({
            type: messagesConstants.FETCH_USERS_LINKED_TRAINERS_REQUEST,
        });
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: messagesConstants.FETCH_USERS_LINKED_TRAINERS_SUCCESS,
                    payload: response.data,
                });

            })
            .catch(error => {
                dispatch({
                    type: messagesConstants.FETCH_USERS_LINKED_TRAINERS_REJECTED,
                    payload: error,
                });
            });
    };
};

const fetchArchivedThread = (keyword, dateFilter) => {
    return async dispatch => {
        let url = '/api/threads?source=archive'
        if (keyword) url += `&search=${keyword}`
        if (dateFilter) url += `&date_filter=${dateFilter}`

        dateFilter || keyword ?
            dispatch({
                type: messagesConstants.FETCH_FILTERED_ARCHIVED_MESSAGES_REQUEST
            }) :
            dispatch({
                type: messagesConstants.FETCH_ARCHIVED_THREAD_REQUEST
            })

        await client
            .get(url)
            .then(response => {
                dateFilter || keyword ?
                    dispatch({
                        type: messagesConstants.FETCH_FILTERED_ARCHIVED_MESSAGES_SUCCESS,
                        payload: response.data.threads
                    }) :
                    dispatch({
                        type: messagesConstants.FETCH_ARCHIVED_THREAD_SUCCESS,
                        payload: response.data.threads
                    })
                dispatch({
                    type: messagesConstants.GET_TOTAL_ARCHIVED_THREADS,
                    payload: response.data.threads_total
                })
            })
            .catch(error => {
                dateFilter || keyword ?
                    dispatch({
                        type: messagesConstants.FETCH_FILTERED_ARCHIVED_MESSAGES_REJECTED,
                        payload: error.data.message
                    }) :
                    dispatch({
                        type: messagesConstants.FETCH_ARCHIVED_THREAD_REJECTED,
                        payload: error.data.message
                    });
            });
    };
}

const moveThreadToArchived = (
    threadID
) => {
    return dispatch => {
        dispatch({
            type: messagesConstants.MOVE_INBOX_THREAD_TO_ARCHIVED_REQUEST,
        });

        const url = `/api/threads/${threadID}/move-to-archive`
        client
            .post(url)
            .then(response => {
                dispatch({
                    type: messagesConstants.MOVE_INBOX_THREAD_TO_ARCHIVED_SUCCESS
                });
                notification.success({
                    message: 'success',
                    description: 'Message Was Archived'
                })
                window.location.reload();
            })
            .catch(error => {
                dispatch({
                    type: messagesConstants.MOVE_INBOX_THREAD_TO_ARCHIVED_REJECTED,
                    payload: error
                });
                notification['error']({
                    message: 'error',
                    description: error.data.message
                })
            })
    }
}

const fetchLatestMessages = () => {
    return async dispatch => {
        let url = '/api/threads/latest-messages'

        dispatch({
            type: messagesConstants.FETCH_LATEST_MESSAGES_REQUEST
        })

        await client
            .get(url)
            .then(response => {
                dispatch({
                    type: messagesConstants.FETCH_LATEST_MESSAGES_SUCCESS,
                    payload: response.data.messages
                })
            })
            .catch(error => {
                dispatch({
                    type: messagesConstants.FETCH_LATEST_MESSAGES_REJECTED,
                    payload: error?.data?.message ?? 'failed to fetch latest messages'
                });
            });
    };
}

const loadMore = (
    page,
    threadType
) => {
    return async dispatch => {
        let url = '/api/threads'
        if (threadType === 'sent') {
            url += '/sent?'
        } else if (threadType === 'inbox') {
            url += '?source=inbox'
        } else if (threadType === 'archived') {
            url += '?source=archive'
        }
        if (page) url += `page=${page}`


        threadType === 'sent' ? dispatch({type: messagesConstants.FETCH_LOAD_MORE_SENT_REQUEST}) :
            threadType === 'inbox' ? dispatch({type: messagesConstants.FETCH_LOAD_MORE_INBOX_REQUEST}) :
                threadType === 'archived' && dispatch({type: messagesConstants.FETCH_LOAD_MORE_ARCHIVED_REQUEST})

        await client
            .get(url)
            .then(response => {
                threadType === 'sent' ? dispatch({
                    type: messagesConstants.FETCH_LOAD_MORE_SENT_SUCCESS,
                    payload: response.data.threads
                }) : threadType === 'inbox' ? dispatch({
                    type: messagesConstants.FETCH_LOAD_MORE_INBOX_SUCCESS,
                    payload: response.data.threads
                    }) : threadType === 'archived' && dispatch({
                    type: messagesConstants.FETCH_LOAD_MORE_ARCHIVED_SUCCESS,
                    payload: response.data.threads
                })

            })
            .catch(error => {
                threadType === 'sent' ? dispatch({
                    type: messagesConstants.FETCH_LOAD_MORE_SENT_REJECTED,
                    payload: error?.data?.message
                }) : threadType === 'inbox' ? dispatch({
                    type: messagesConstants.FETCH_LOAD_MORE_INBOX_REJECTED,
                    payload: error?.data?.message
                }) : threadType === 'archived' && dispatch({
                    type: messagesConstants.FETCH_LOAD_MORE_ARCHIVED_REJECTED,
                    payload: error?.data?.message
                })
            });
    };
}


export const messagesActions = {
    createMessageSubmitted,
    fetchSentThreads,
    conversationReplaySubmitted,
    fetchInboxThread,
    fetchConversations,
    fetchUserTrainers,
    fetchTrainers,
    moveThreadToArchived,
    fetchArchivedThread,
    fetchLatestMessages,
    loadMore
}
