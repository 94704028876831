import React, { useEffect, useState } from 'react';
import './ImportActivitiesPage.scss';
import { Upload, Button, Form, Tabs, Row, Divider, Select, message, notification, Alert} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { axiosDefaultClient } from '../settings/axios.settings';

const { Option } = Select;

const ImportActivitiesPage = props => {
    const [form] = Form.useForm();
    const [errors, serErrors] = useState([]);
    const [loadingImport, setLoadingImport] = useState(false);
    const [file, setFile] = useState(null);

    const handleFile = (info) => {
        if (info.file.status !== 'removed') {
            const uploadedFile = info.fileList[0].originFileObj;
            setFile(uploadedFile);
        }
    };
    
    const checkEditor = (_, value) => {
        if (!file) {
            return Promise.reject(new Error('Please upload a valid Excel file.'));
        }
        return Promise.resolve();
    };

   

    const submitForm = async (values) => {
        if (!file) {
            message.error("Please upload a file first!");
            return;
        }

        setLoadingImport(true);

        const formData = new FormData();
        formData.append("import_file", file);
        formData.append("activityType", values.activityType);
        serErrors([]);
        try {
            const response = await axiosDefaultClient.post('/api/activities/import/excel', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            notification.success({
                message: response.status === 201 ? "Activity imported and created successfully." : response?.statusText,
            });
        } catch (error) {
            notification.error({
                message: error?.data?.message,
            });
            serErrors(error?.data?.payload.errors);
        } finally {
            setLoadingImport(false);
            form.resetFields();
        }

    };

    return (
        <div className="componentContainer">
            <h1>Import Excel File</h1>
            <Form form={form} layout="vertical" onFinish={submitForm}>
                <Tabs defaultActiveKey="1" tabPosition="top">
                    <Row style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Item
                                name="activityType"
                                label="Select Activity Type"
                                rules={[{ required: true, message: "Please select an activity type!" }]}
                        >
                            <Select placeholder="Choose an activity type">
                                <Option value="flashCard">Flash Card</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="excelFile"
                            label="Upload Excel File"
                            rules={[{ validator: checkEditor }]}
                        >
                            <Upload
                                beforeUpload={() => false} // Prevent auto-upload
                                onChange={handleFile}
                                accept=".xlsx"
                                showUploadList={true}
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                    </Row>
                </Tabs>
                {errors.length > 0 && (
                    <Alert
                        message="Import Errors"
                        description={
                            <ul style={{ paddingLeft: 20 }}>
                                {errors.map((err, index) =>
                                    err.errors.map((errorMsg, i) => (
                                        <li key={`${index}-${i}`}>{errorMsg}</li>
                                    ))
                                )}
                            </ul>
                        }
                        type="error"
                        showIcon
                    />
                )}
                <Divider />

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loadingImport}>
                        Import and create
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ImportActivitiesPage;
