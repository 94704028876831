import React from 'react';
import AdminMigrateContnet from '../pages/AdminMigrateContnet';
import LearningFocusesListPage from '../pages/LearningFocusesListPage';
import CustomerPackages from '../pages/CustomerActivities';
import ScormCustomers from '../pages/ScormCustomers';
import MyContent from '../pages/MyContent';
import { history } from '../utils/history';
import ImportActivitiesPage from '../pages/ImportActivitiesPage';

const ProtectedRoute = React.lazy(() =>
    import('../components/Layout/ProtectedRoute'),
);
const BuilderPage = React.lazy(() =>
    import('../pages/BuilderPage'),
);
const SharedFilesPage = React.lazy(() =>
    import('../pages/SharedFilesPage'),
);
const AdminHelpPage = React.lazy(() =>
    import('../pages/AdminHelpPage'),
);
const SitesListPage = React.lazy(() =>
    import('../pages/SitesListPage'),
);
const SitesManagePage = React.lazy(() =>
    import('../pages/SitesManagePage'),
);
const SiteView = React.lazy(() => import('../pages/SiteView'));
const AdminConfigurationPage = React.lazy(() =>
    import('../pages/AdminConfigurationPage'),
);
const AdminContactsPage = React.lazy(() =>
    import('../pages/AdminContactsPage'),
);
const AdminContactGroupsPage = React.lazy(() =>
    import('../pages/ContactGroups'),
);
const CreateAdminContactsPage = React.lazy(() =>
    import('../pages/CreateContact'),
);
const ModulesListPage = React.lazy(() =>
    import('../pages/ModulesListPage'),
);
const ModuleManagePage = React.lazy(() =>
    import('../pages/ModuleManagePage'),
);
const LearningPathwaysList = React.lazy(() =>
    import('../pages/LearningPathwaysListPage'),
);
const LearningPathwayManagePage = React.lazy(() =>
    import('../pages/LearningPathwayManagePage'),
);
const LanguageLevelTypesListPage = React.lazy(() =>
    import(
        '../pages/LanguageLevelTypesListPage'
    ),
);
const LanguageLevelTypeManagePage = React.lazy(() =>
    import(
        '../pages/LanguageLevelTypeManagePage'
    ),
);
const LearningTypesManagePage = React.lazy(() =>
    import(
        '../pages/LearningTypesManagePage'
    ),
);
const LearningTypesListPage = React.lazy(() =>
    import(
        '../pages/LearningTypesListPage'
    ),
);
const ActivitiesListPage = React.lazy(() =>
    import('../pages/ActivitiesListPage'),
);

const adminViewActivityPage = React.lazy(() =>
    import('../pages/ActivityPage')
)
// const QuestionnairesList = React.lazy(() =>
//     import(
//         '../components/Admin/Questionair/QuestionaierListPage/QuestionnairesList'
//     ),
// );
const QuestionnairesList = React.lazy(() =>
    import(
        '../pages/QuestionnairesListPage'
    ),
);
// const ManageQuestionnaires = React.lazy(() =>
//     import(
//         '../components/Admin/Questionair/ManageQuestionaier/ManageQuestionaier'
//     ),
// );
const ManageQuestionnaires = React.lazy(() =>
    import(
        '../pages/NewQuestionnaire/ManageActivityPage'
    ),
);
const ManageUserPage = React.lazy(() =>
    import('../pages/ManageUserPage'),
);
const CreateUsersCSV = React.lazy(() =>
    import('../pages/CreateUsersCSV'),
);
const PermissionsListPage = React.lazy(() =>
    import('../pages/PermissionsListPage'),
);
const RolesListPage = React.lazy(() =>
    import('../pages/RolesListPage'),
);
const ManageRolePage = React.lazy(() =>
    import('../pages/ManageRolePage'),
);
const UserGroupsListPage = React.lazy(() =>
    import('../pages/UserGroupsListPage'),
);
const ManageUserGroupPage = React.lazy(() =>
    import('../pages/ManageUserGroupPage'),
);
const CoursesListPage = React.lazy(() =>
    import('../pages/CoursesListPage'),
);
const CourseManagePage = React.lazy(() =>
    import('../pages/CourseManagePage'),
);
const CourseBundlesListPage = React.lazy(() =>
    import('../pages/CourseBundlesListPage'),
);
const CourseBundleManagePage = React.lazy(() =>
    import('../pages/CourseBundleManagePage'),
);
const HybridDashboardPage = React.lazy(() =>
    import('../pages/HybridDashboardPage'),
);
const LearnerDashboardPage = React.lazy(() =>
    import('../pages/LearnerDashboardPage'),
);
const UsersListPage = React.lazy(() =>
    import('../pages/UsersListPage'),
);
const ManageActivityPage = React.lazy(() =>
    import('../pages/Activity/ManageActivityPage'),
);
const NewManageActivityPage = React.lazy(() =>
    import('../pages/newActivity/ManageActivityPage'),
);
const UserReportingPage = React.lazy(() =>
    import('../pages/UserReportingPage'),
);
const UserGroupReportingPage = React.lazy(() =>
    import('../pages/UserGroupReportingPage'),
);
const AuditLogs = React.lazy(() =>
    import('../pages/AuditLogs'),
);
const LearningFocusesManagePage = React.lazy(() =>
    import(
        '../pages/LearningFocusesManagePage'
    ),
);
const InterfaceLanguagesListPage = React.lazy(() =>
    import(
        '../pages/interfaceLanguages/LearningLanguagesListPage'
    ),
);
const EditInterfaceLanguages = React.lazy(() =>
    import(
        '../pages/interfaceLanguages/EditLearningLanguages'
    ),
);
const ContentLanguagesListPage = React.lazy(() =>
    import(
        '../pages/contentLanguage/LearningLanguagesListPage'
    ),
);
const PictureDB = React.lazy(() =>
    import('../pages/PicturesDb'),
);
const EditPicturesPage = React.lazy(() =>
    import('../pages/EditPicturesPage'),
);
const AdminSettings = React.lazy(() =>
    import('../pages/settings'),
);
const SitesManageEmailList = React.lazy(() =>
    import('../pages/Email/List'),
);
const SitesManageEmailCreate = React.lazy(() =>
    import('../pages/Email/Create'),
);
const SitesManageEmailEdit = React.lazy(() =>
    import('../pages/Email/Edit'),
);

const UserGroupsContent = React.lazy(() =>
    import('../pages/UserGroupContent'),
);
const UserModules = React.lazy(() =>
    import('../pages/UserModules'),
);

const SelectePathwayToCustomisePage = React.lazy(() =>
    import('../pages/SelectPathwayToCustomisePage'),
);

const LearningPathwayView = React.lazy(() =>
    import('../pages/LearningPathwayView'),
);

const SectionPage = React.lazy(() =>
    import('../pages/SectionPage'),
);

const LearnerModulePage = React.lazy(() =>
    import('../pages/Learner/ModulePage'),
);

const ModuleViewPage = React.lazy(() =>
    import('../pages/ModuleView'),
);

const CourseBundleViewPage = React.lazy(() =>
    import('../pages/CourseBundleView'),
);

const courseViewPage = React.lazy(() =>
    import('../pages/CourseView'),
);

const TrainerUsersPage = React.lazy(() =>
    import('../pages/trainerReportingPage/ReportingPage'),
);

const UserProgressProfile = React.lazy(() =>
    import(
        '../pages/UserProgressProfile'
    ),
);

const TrainerContact = React.lazy(() =>
    import('../pages/TrainerContactPage'),
);
const WishListPage = React.lazy(() => import('../pages/WishList'));
const SearchPage = React.lazy(() => import('../pages/Search'));
const TimeReporting = React.lazy(() =>
    import('../pages/TimeReporting'),
);
const ReportingPage = React.lazy(() =>
    import('../pages/adminReportingPage/ReportingPage'),
);

const ExploreAndAssignContent = React.lazy(() =>
    import(
        '../pages/ExploreAndAssignContent'
    ),
);

const ShowContentPage = React.lazy(() =>
    import('../pages/ShowContentPage'),
);

const AssignModules = React.lazy(() =>
    import('../pages/AssignModules'),
);

const BuildAndAssign = React.lazy(() =>
    import('../pages/BuildAndAssign'),
);

const InterfaceLanguage = React.lazy(() =>
    import('../pages/InterfaceLanguage'),
);
const UserProgressProfileV2 = React.lazy(() =>
    import('../pages/UserProgressProfileV2'),
);
const Messages = React.lazy(() => import('../pages/messages'))
const Feedback = React.lazy(() => import('../pages/Feedback'))
const ManageFeedback = React.lazy(() => import('../pages/ManageFeedback'))
const viewFeedback = React.lazy(() => import('../pages/ViewFeedback'))
const trainerModulePage = React.lazy(() => import ('../pages/trainer/ModulePage'))
const ZeroResultSearchesAuditsPage = React.lazy(() => import ('../pages/ZeroResultSearchesAuditsPage'))
const FAQManagementPage = React.lazy(() => import ('../pages/FAQManagementPage'));


// testing merging
const AdminRoutes = [
    <ProtectedRoute
        key="/admin/feedback/view/:id"
        exact
        path="/admin/feedback/view/:id"
        component={viewFeedback}
    />,
    <ProtectedRoute
        key="/admin/feedback"
        exact
        path="/admin/feedback"
        component={Feedback}
    />,
    <ProtectedRoute
        key="/admin/feedback/manage"
        exact
        path="/admin/feedback/manage"
        component={ManageFeedback}
    />,
    <ProtectedRoute
        key="/admin/feedback/manage/:id"
        exact
        path="/admin/feedback/manage/:id"
        component={ManageFeedback}
    />,
    <ProtectedRoute
        key="/admin/messages"
        exact
        path="/admin/messages"
        component={Messages}
    />,
    <ProtectedRoute
        key="/admin/scorm-cursomers/:customerId"
        exact
        path="/admin/scorm-cursomers/:customerId"
        component={CustomerPackages}
    />,
    <ProtectedRoute
        key="/admin/scorm-cursomers"
        exact
        path="/admin/scorm-cursomers"
        component={ScormCustomers}
    />,
    <ProtectedRoute
        key="/admin/build/assign"
        exact
        path="/admin/build/assign"
        component={BuildAndAssign}
    />,
    <ProtectedRoute
        key="/admin/assign/modules/:id"
        exact
        path="/admin/assign/modules/:id"
        component={AssignModules}
    />,
    <ProtectedRoute
        key="/admin/showContent"
        exact
        path="/admin/showContent"
        component={ShowContentPage}
    />,
    <ProtectedRoute
        key="/admin/explor/assign"
        exact
        path="/admin/explor/assign"
        component={ExploreAndAssignContent}
    />,
    <ProtectedRoute
        key="/admin/ExploreAndAssign"
        exact
        path="/admin/ExploreAndAssign"
        component={ExploreAndAssignContent}
    />,
    <ProtectedRoute
        key="/admin/trainerContact"
        exact
        path="/admin/trainerContact"
        component={TrainerContact}
    />,
    <ProtectedRoute
        key="/admin/timereport"
        exact
        path="/admin/timereport"
        component={TimeReporting}
    />,
    <ProtectedRoute
        key="/admin/report/user/:id"
        exact
        path="/admin/report/user/:id"
        component={UserProgressProfileV2}
    />,
    <ProtectedRoute
        key="/old/admin/report/user/:id"
        exact
        path="/old/admin/report/user/:id"
        component={UserProgressProfile}
    />,
    <ProtectedRoute
        key="/admin/report"
        exact
        path="/admin/report"
        component={ReportingPage}
    />,
    <ProtectedRoute
        key="/admin/search"
        exact
        path="/admin/search"
        component={ExploreAndAssignContent}
    />,
    <ProtectedRoute
        key="/admin/wishlist"
        exact
        path="/admin/wishlist"
        component={WishListPage}
    />,
    <ProtectedRoute
        key="/admin/users/reporting"
        exact
        path="/admin/users/reporting"
        component={TrainerUsersPage}
    />,
    <ProtectedRoute
        key="/admin/users/modules/:id"
        exact
        path="/admin/users/modules/:id"
        component={UserModules}
    />,
    <ProtectedRoute
        key="/admin/user-group/:groupId"
        exact
        path="/admin/user-group/:groupId"
        component={UserGroupsContent}
    />,
    <ProtectedRoute
        key="/admin/courses/view-id/view"
        path="/admin/courses/:id/view"
        component={courseViewPage}
        exact
    />,
    <ProtectedRoute
        exact
        key="/admin/courses/manage"
        path="/admin/courses/manage"
        component={CourseManagePage}
    />,
    <ProtectedRoute
        exact
        key="/admin/courses/:id"
        path="/admin/courses/:id"
        component={CourseManagePage}
    />,
    <ProtectedRoute
        key="/admin/courses"
        path="/admin/courses"
        component={CoursesListPage}
    />,
    <ProtectedRoute
        key="/admin/course-bundles/manage"
        path="/admin/course-bundles/manage"
        component={CourseBundleManagePage}
    />,
    <ProtectedRoute
        key="/admin/course-bundles/:id"
        path="/admin/course-bundles/:id"
        component={CourseBundleManagePage}
        exact
    />,
    <ProtectedRoute
        key="/admin/course-bundles"
        path="/admin/course-bundles"
        component={CourseBundlesListPage}
        exact
    />,
    <ProtectedRoute
        key="/admin/course-bundles/id/view"
        path="/admin/course-bundles/:id/view"
        component={CourseBundleViewPage}
    />,
    <ProtectedRoute
        key="/admin/user-groups/manage/:userGroupID"
        component={ManageUserGroupPage}
        path="/admin/user-groups/manage/:userGroupID"
    />,
    <ProtectedRoute
        key="/admin/user-groups/manage"
        path="/admin/user-groups/manage"
        component={ManageUserGroupPage}
    />,
    <ProtectedRoute
        path="/admin/user-groups/:userGroupID/report"
        component={UserGroupReportingPage}
        key="/admin/user-groups/report"
    />,
    <ProtectedRoute
        path="/admin/user-groups"
        component={UserGroupsListPage}
        key="/admin/user-groups"
    />,
    <ProtectedRoute
        path="/admin/users/setup-trainer/manage"
        component={ManageUserPage}
        key="/admin/users/manage/setup-trainer"
    />,
    <ProtectedRoute
        path="/admin/users/manage/:userID"
        component={ManageUserPage}
        key="/admin/users/manage-withid"
    />,
    <ProtectedRoute
        path="/admin/users/import"
        component={CreateUsersCSV}
        key="/admin/users/import"
    />,
    <ProtectedRoute
        path="/admin/users/manage"
        component={ManageUserPage}
        key="/admin/users/manage"
    />,
    <ProtectedRoute
        path="/admin/users/:userID/report"
        component={UserReportingPage}
        key="/admin/users/report"
    />,
    <ProtectedRoute
        path="/admin/users"
        component={UsersListPage}
        key="/admin/users"
    />,
    <ProtectedRoute
        path="/admin/roles/manage"
        component={ManageRolePage}
        key="/admin/roles/manage"
    />,
    <ProtectedRoute
        path="/admin/roles"
        component={RolesListPage}
        key="/admin/roles"
    />,
    <ProtectedRoute
        key="/admin/permissions"
        path="/admin/permissions"
        component={PermissionsListPage}
    />,
    <ProtectedRoute
        path="/activities/create"
        component={BuilderPage}
        key="/activities/create"
    />,
    <ProtectedRoute
        path="/admin/activities/import"
        component={ImportActivitiesPage}
        key="/admin/activities/import"
    />,
    <ProtectedRoute
        path="/admin/shared-files"
        component={SharedFilesPage}
        key="/admin/shared-files"
    />,
    <ProtectedRoute
        key="/admin/questionnaires/manage/:id"
        path="/admin/questionnaires/manage/:id"
        component={ManageQuestionnaires}
    />,
    <ProtectedRoute
        key="/admin/questionnaires/manage"
        path="/admin/questionnaires/manage"
        component={ManageQuestionnaires}
    />,
    <ProtectedRoute
        key="/admin/questionnaires"
        path="/admin/questionnaires"
        component={QuestionnairesList}
    />,
    <ProtectedRoute
        path="/admin/activity/:activityID"
        // component={ManageActivityPage}
        component={adminViewActivityPage}
        key="/admin/activities/view-activity"
    />,
    <ProtectedRoute
        path="/admin/activities/manage/:activityID"
        // component={ManageActivityPage}
        component={NewManageActivityPage}
        key="/admin/activities/manage-withid"
    />,
    <ProtectedRoute
        path="/admin/activities/manage"
        // component={ManageActivityPage}
        component={NewManageActivityPage}
        key="/admin/activities/manage"
    />,
    <ProtectedRoute
        path="/admin/activities/old-manage/:activityID"
        component={ManageActivityPage}
        key="/admin/activities/old-manage/:activityID"
    />,
    <ProtectedRoute
        path="/admin/activities/old-manage"
        component={ManageActivityPage}
        key="/admin/activities/old-manage"
    />,
    <ProtectedRoute
        path="/admin/activities"
        component={ActivitiesListPage}
        key="/admin/activities"
    />,
    <ProtectedRoute
        path="/admin/help"
        component={AdminHelpPage}
        key="/admin/help"
    />,
    <ProtectedRoute
        key="/admin/sites/:id"
        path="/admin/sites/:id"
        component={SitesManagePage}
        history={history}
        exact
    />,
    <ProtectedRoute
        key=" /admin/sites/copy/:id"
        path="/admin/sites/copy/:id"
        component={SitesManagePage}
        history={history}
        exact
        copy={true}
    />,

    <ProtectedRoute
        key="/admin/sites/create"
        path="/admin/sites/create"
        component={SitesManagePage}
        history={history}
        exact
    />,

    <ProtectedRoute
        key="/admin/sites/view/:id"
        path="/admin/sites/view/:id"
        component={SiteView}
        history={history}
        exact
    />,
    <ProtectedRoute
        key="/admin/sites/:id/emails"
        path="/admin/sites/:id/emails"
        component={SitesManageEmailList}
        history={history}
        exact
    />,
    <ProtectedRoute
        key="/admin/sites/:id/emails/create"
        path="/admin/sites/:id/emails/create"
        component={SitesManageEmailCreate}
        history={history}
        exact
    />,
    <ProtectedRoute
        key="/admin/sites/:id/emails/:email_id"
        path="/admin/sites/:id/emails/:email_id"
        component={SitesManageEmailEdit}
        history={history}
        exact
    />,
    <ProtectedRoute
        path="/admin/sites"
        component={SitesListPage}
        key="/admin/sites"
    />,
    <ProtectedRoute
        key="/admin/configuration"
        path="/admin/configuration"
        component={AdminConfigurationPage}
    />,
    <ProtectedRoute
        path="/admin/contacts"
        component={AdminContactsPage}
        key="/admin/contacts"
        exact
    />,
    <ProtectedRoute
        path="/admin/contacts/group"
        component={AdminContactGroupsPage}
        key="/admin/contacts/group"
        exact
    />,
    <ProtectedRoute
        path="/admin/contacts/create"
        component={CreateAdminContactsPage}
        key="/admin/contacts/create"
        exact
    />,
    <ProtectedRoute
        path="/admin/contacts/:id"
        component={CreateAdminContactsPage}
        key="/admin/contacts/:id"
        exact
    />,
    <ProtectedRoute
        key="/admin/modules/manage"
        path="/admin/modules/manage"
        component={ModuleManagePage}
        exact
    />,
    <ProtectedRoute
        key="/admin/modules/with-id"
        path="/admin/modules/manage/:id"
        component={ModuleManagePage}
        exact
    />,
    <ProtectedRoute
        path="/admin/modules"
        component={ModulesListPage}
        key="/admin/modules"
        exact
    />,
    <ProtectedRoute
        key="/admin/learning-pathways/:learningPathwayID/manage"
        path="/admin/learning-pathways/:learningPathwayID/manage"
        component={LearningPathwayManagePage}
        exact
    />,
    <ProtectedRoute
        key="/admin/learning-pathways/manage"
        path="/admin/learning-pathways/manage"
        component={LearningPathwayManagePage}
        exact
    />,
    <ProtectedRoute
        key="/admin/learning-pathways/view/standard"
        path="/admin/learning-pathways/view/standard"
        component={LearnerDashboardPage}
        exact
    />,
    // <ProtectedRoute
    //     key="/admin/learning-pathways/view/hybrid"
    //     path="/admin/learning-pathways/view/hybrid"
    //     component={HybridDashboardPage}
    // />,
    <ProtectedRoute
        key="/admin/learning-pathways/view-id/view"
        path="/admin/learning-pathways/:id/view"
        component={LearningPathwayView}
        exact
    />,
    <ProtectedRoute
        key="/admin/modules/view-id/view"
        path="/admin/modules/:id/view"
        component={ModuleViewPage}
        exact
    />,
    <ProtectedRoute
        key="/admin/section-page"
        path="/admin/section-page/:sectionID"
        component={SectionPage}
        history={history}
    />,
    <ProtectedRoute
        key="/admin/modules/module-id/activities"
        path="/admin/modules/:moduleID/activities"
        component={LearnerModulePage}
        history={history}
    />,
    <ProtectedRoute
        key="/admin/learning-pathways"
        path="/admin/learning-pathways"
        component={LearningPathwaysList}
        exact
    />,
    <ProtectedRoute
        key="/admin/language-level-types/create"
        path="/admin/language-level-types/create"
        component={LanguageLevelTypeManagePage}
        history={history}
    />,
    <ProtectedRoute
        key="/admin/language-level-types/edit"
        path="/admin/language-level-types/edit"
        component={LanguageLevelTypeManagePage}
        history={history}
    />,
    <ProtectedRoute
        key="/admin/language-level-types"
        path="/admin/language-level-types"
        component={LanguageLevelTypesListPage}
    />,

    <ProtectedRoute
        key="/admin/learning-types/manage"
        path="/admin/learning-types/manage"
        component={LearningTypesManagePage}
        exact
    />,

    <ProtectedRoute
        //   /admin/users/manage/:userID
        key="/admin/learning-types/manage-withid"
        path="/admin/learning-types/manage/:id"
        component={LearningTypesManagePage}
        exact
    />,
    <ProtectedRoute
        key="/admin/learning-types/edit"
        path="/admin/learning-types/edit"
        component={LearningTypesManagePage}
        exact
    />,
    <ProtectedRoute
        key="/admin/learning-types"
        path="/admin/learning-types"
        component={LearningTypesListPage}
        exact
    />,

    <ProtectedRoute
        key="/admin/audit-logs"
        path="/admin/audit-logs"
        component={AuditLogs}
    />,
    <ProtectedRoute
        key="/admin/migrate-content"
        path="/admin/migrate-content"
        component={AdminMigrateContnet}
    />,
    <ProtectedRoute
        key="/admin/learning-focuses"
        path="/admin/learning-focuses"
        component={LearningFocusesListPage}
        exact
    />,
    <ProtectedRoute
        key="/admin/learning-focuses/manage"
        path="/admin/learning-focuses/manage"
        component={LearningFocusesManagePage}
        exact
    />,
    <ProtectedRoute
        key="/admin/learning-focuses/manage-withid"
        path="/admin/learning-focuses/manage/:id"
        component={LearningFocusesManagePage}
        exact
    />,
    <ProtectedRoute
        key="/admin/interface-languages"
        path="/admin/interface-languages"
        component={InterfaceLanguagesListPage}
    />,
    <ProtectedRoute
        key="/admin/edit-interface-languages"
        path="/admin/edit-interface-languages/:code"
        component={EditInterfaceLanguages}
    />,
    <ProtectedRoute
        key="/admin/content-languages"
        path="/admin/content-languages"
        component={ContentLanguagesListPage}
    />,
    <ProtectedRoute
        key="/admin/picture-db"
        path="/admin/picture-db"
        component={PictureDB}
        exact
    />,
    <ProtectedRoute
        key="/admin/picture-db/edit-pictures"
        path="/admin/picture-db/edit-pictures"
        component={EditPicturesPage}
    />,
    <ProtectedRoute
        key="/admin/settings"
        path="/admin/settings"
        component={AdminSettings}
    />,
    <ProtectedRoute
        key="/admin/interface-language"
        path="/admin/interface-language"
        component={InterfaceLanguage}
        exact
    />,
    <ProtectedRoute
        key="/admin/learners/saved-content"
        path="/admin/learners/saved-content"
        component={MyContent}
        exact
    />,
    <ProtectedRoute
        key="/admin/modules/module-id/view/activities"
        path="/admin/modules/:moduleID/view/activities"
        component={trainerModulePage}
        exact
    />,
    <ProtectedRoute
        key="/admin/empty-searches-audits"
        path="/admin/empty-searches-audits"
        component={ZeroResultSearchesAuditsPage}
        exact
    />,
    <ProtectedRoute
        key="/admin/FAQS-management"
        path="/admin/FAQS-management"
        component={FAQManagementPage}
        exact
    />,
];

export default AdminRoutes;
