import axios from 'axios';
import { userConstants } from '../constants/user.constants';
import { userService } from '../../services/user.service';
import { firstLoginData } from '../reducers/firstLoginReducer';
import authenticationConstants from '../constants/authentication.constants';
import { axiosDefaultClient } from '../../settings/axios.settings';
import { Modal, notification } from 'antd';
import { history } from '../../utils/history';
import {feedbackTrainerProgressConstants} from "../constants/feedbackTrainerProgress.constants";
import { storeEncryptedUserInLocalStorage } from '../../utils/common';
import * as Sentry from "@sentry/react";

const client = axiosDefaultClient;
const { CancelToken } = axios;
let cancelSearch;

async function changeLanguageInterface(langCode) {
    try {
        const url = `/api/users/get-language-interface/${langCode}`;
        const response = await client.get(url);
        response.data?.lang &&
            localStorage.setItem(
                'language',
                JSON.stringify(response.data.lang),
            );
    } catch (error) {
        console.log(error);
    }
}

async function updateLanguageInterface(lang, userID) {
    try {
        const url = `/api/learner/${userID}/profile/update-lang`;
        await client.post(url, { contentLangID: lang.langID });
    } catch (error) {
        console.log(error);
    }
}

async function getLanguage(langCode, userID) {
    try {
        const url = `/api/user/${userID}/languages`;
        const response = await client.get(url);
        const lang = response?.data?.payload?.find(
            (lang) => lang.langCode === langCode,
        );
        localStorage.setItem('langId', lang.langID);
        localStorage.setItem('langFlag', lang.flag);
        localStorage.setItem('lang', JSON.stringify(lang ?? {}));
        return lang;
    } catch (error) {
        console.log(error);
    }
}

async function redirectBasedOnPathwaysCount(
    langPathwaysANDBundlesCount,
    currentLangCode,
    user,
) {
    const languagePriority = ['en', 'es', 'fr', 'de', 'it', 'pt'];

    // First, check the current language for pathways or bundles
    const currentLangData = langPathwaysANDBundlesCount[currentLangCode];

    if (currentLangData?.pathwayCount > 0) {
        // Redirect to dashboard if there are pathways in the current language
        history.push('/learner/dashboard');
        window.location.reload();
        return;
    } else {
        // If no pathways in the current language, check other languages
        for (let langCode of languagePriority) {
            const langData = langPathwaysANDBundlesCount[langCode];
            if (langData?.pathwayCount > 0) {
                // Redirect to dashboard if pathways found in another language
                const lang = await getLanguage(langCode, user.userID);
                await updateLanguageInterface(lang, user.userID);
                await changeLanguageInterface(langCode);
                history.push('/learner/dashboard');
                window.location.reload();
                return;
            }
        }
    }

    //here means that the learner has no pathways in any language, so do the same thing but for course bundles
    if (currentLangData?.courseBundlesCount > 0) {
        // Redirect to library if there are bundles in the current language
        history.push('/learner/library');
        window.location.reload();
        return;
    } else {
        // If no  bundles in the current language, check other languages
        for (let langCode of languagePriority) {
            const langData = langPathwaysANDBundlesCount[langCode];

            if (langData?.courseBundlesCount > 0) {
                const lang = await getLanguage(langCode, user.userID);
                await updateLanguageInterface(lang, user.userID);
                await changeLanguageInterface(langCode);
                history.push('/learner/library');
                window.location.reload();
                return;
            }
        }
    }

    //if learner has no bundels and no pathways go to dashboard
    history.push('/learner/dashboard');
    window.location.reload();
}

function login(username, password, history, location) {
    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }

    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }

    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }

    return dispatch => {
        dispatch(request({ username }));

        userService
            .login(username, password, history, location)
            .then(async (responseData) => {
                dispatch(success(responseData.user));
                localStorage.setItem('langId', responseData.langID);
                if (responseData.loginPopUpMsg) {
                    localStorage.setItem(
                        'loginPopUpMsg',
                        responseData.loginPopUpMsg,
                    );
                }
                localStorage.setItem('langFlag', responseData.langFlag);
                responseData.contentLanguage &&
                    localStorage.setItem(
                        'lang',
                        JSON.stringify(responseData.contentLanguage),
                    );

                // Set Sentry user details
                Sentry.setUser({
                    id: responseData.user.userID,
                    username: responseData.user.username,
                });

                if (responseData.user.role !== 'learner') {
                    history.push('/dashboard');
                }
                if (responseData.user.role === 'learner') {
                    try {
                        const langPathwaysANDBundelsCount = await client.get(
                            '/api/user/language-stats',
                        );
                        await redirectBasedOnPathwaysCount(
                            langPathwaysANDBundelsCount?.data?.payload,
                            responseData?.contentLanguage?.langCode,
                            responseData.user,
                        );
                    } catch (error) {
                        console.log(error);
                    }
                }
                if (
                    responseData.firstLogin &&
                    responseData.user.role === 'learner'
                ) {
                    history.push('/firstlogin');
                }
            })
            .catch(error => {
                dispatch(failure(error));
            });
    };
}

function resetError() {
    return { type: userConstants.LOGIN_FAILURE, error: '' };
}

function logout(historObj, refresh = false) {
    if (localStorage.getItem('user')) {
        historObj.push('/login');
        userService.logout(refresh)

        // Clear Sentry user details
        Sentry.setUser(null);

        // userService.logout().then(() => {
        //     history.push('/login')
        // });
    }
    return { type: userConstants.LOGOUT };
}

const fetchUsers = (
    page,
    pageSize,
    filters = [],
    type,
    with_pagination,
    eflexAdmin = false,
) => {
    // page = 1,
    //     pageSize = 10,
    return dispatch => {
        // let url = `/api/users?page=${page.toString()}&per_page=${pageSize}`;
        let url = `/api/users?`;
        if (page) url = url + `&page=${page}`;
        if (pageSize) url = url + `&per_page=${pageSize}`;
        if (filters) {
            // filters.forEach(filter => {
            //     const attrs = Object.getOwnPropertyNames(filter);
            //     attrs.forEach(attr => {
            //         url += `&filters[]=${attr}=${filter[attr]}`;
            //     });
            // });
            // url = `/api/users?page=${page.toString()}&per_page=${pageSize}`
            url =
                url +
                `&site=${filters.siteID ? filters.siteID : ''}&keyword=${
                    (filters.keyword && filters.keyword !== 'null') ? encodeURIComponent(filters.keyword) : ''
                }&created_date=${
                    filters.creationDate ? filters.creationDate : ''
                }&end_access=${filters.endDate ? filters.endDate : ''}&role=${
                    filters.role ? filters.role : ''
                }&group=${filters.userGroup ? filters.userGroup : ''}&status=${
                    filters.state ? filters.state : ''
                }&trainer=${filters.trainers ? filters.trainers : ''}&order_by=${
                    filters.orderBy ? filters.orderBy : ''
                }&order_key=${filters.orderKey === 'timeSpent' ? 'trackingTotalTime' :  filters.orderKey ? filters.orderKey : ''}&content_language=${
                filters.language ? filters.language : ''}`;
        }
        if (eflexAdmin) url = url + `&eflexAdmin=${eflexAdmin}`;
        if (type) url = url + `&type=${type}`;
        if (with_pagination) url = url + `&with_pagination=${with_pagination}`;
        if (filters?.includeGroupTrainers) url = url + `&includeGroupTrainers=${filters.includeGroupTrainers}`;

        dispatch({
            type: userConstants.FETCH_USERS_REQUEST,
        });
        dispatch({
            type: feedbackTrainerProgressConstants.GET_TRAINER_LEARNERS_REQUEST
        })
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: userConstants.FETCH_USERS_SUCCESS,
                    payload: response.data,
                });
                dispatch({
                    type: feedbackTrainerProgressConstants.GET_TRAINER_LEARNERS_SUCCESS,
                    payload: response.data.data
                })
            })
            .catch(error => {
                dispatch({
                    type: userConstants.FETCH_USERS_REJECTED,
                    payload: error,
                });
                dispatch({
                    type: feedbackTrainerProgressConstants.GET_TRAINER_LEARNERS_REJECTED,
                    payload: error?.data?.message,
                })
            });
    };
};

const fetchAllUsers = (
    filters,
    type,
    savedContentTrainerID,
    per_page = undefined,
    reporting = false,
    pagination,
) => {
    return dispatch => {
        let url = `/api/users?`;
        if (filters?.keyword) url = url + `keyword=${filters.keyword}`;
        if (type) url = url + `&type=${type}`;
        if (savedContentTrainerID)
            url = url + `&savedContentTrainerID=${savedContentTrainerID}`;
        if (per_page) url = url + `&per_page=${per_page}`;
        if (reporting) url = url + `&reporting=${reporting}`;
        if(pagination){
            url = `${url}&page=${pagination.page}&per_page=${pagination.per_page}`;
            if(pagination?.keyword)  url = `${url}&keyword=${pagination.keyword}`;
        }
        dispatch({
            type: userConstants.FETCH_USERS_REQUEST,
        });
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: userConstants.FETCH_USERS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: userConstants.FETCH_USERS_REJECTED,
                    payload: error,
                });
            });
    };
};

const fetchUserTrainers = (page = 1, pageSize = 10, userId) => {
    return dispatch => {
        // let url = `/api/users/${userId}/trainers?page=${page?.toString()}&per_page=${pageSize}`;
        let url = `/api/users/${userId}/trainers`;

        dispatch({
            type: userConstants.FETCH_USERS_REQUEST,
        });
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: userConstants.FETCH_USERS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: userConstants.FETCH_USERS_REJECTED,
                    payload: error,
                });
            });
    };
};

const fetchLearners = (
    page = 1,
    pageSize = 10,
    siteID,
    status,
    keyword = '',
    groupId,
) => {
    return dispatch => {
        // const url = `/api/users?page=${page.toString()}&per_page=${pageSize}&filters[]=siteID=${siteID}&filters[]=role=learner&type=select`;
        let url = `/api/users?page=${page}&per_page=${pageSize}&site=${siteID}&role=learner&userGroupFiltered=${groupId}&extra_key=code`;
        if (status) url += `&status=${status}`
        if (keyword) url += `&keyword=${keyword}`;
        dispatch({
            type: userConstants.FETCH_USERS_LEARNERS_REQUEST,
        });
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: userConstants.FETCH_USERS_LEARNERS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: userConstants.FETCH_USERS_LEARNERS_REJECTED,
                    payload: error,
                });
            });
    };
};

const fetchTrainers = (page, size, siteID, status, groupId, order_by, order_key, type, keyword) => {
    // /api/users?page=1&per_page=10&site=94&keyword=trainer-user&created_date=&end_access=&role=trainer&group=&status=&trainer=

    return dispatch => {
        let url = '';
        if (siteID) {
            url = `/api/users?site=${siteID}&_role=trainer&role=trainer`;
        } else 
            url =
                `/api/users?_role=trainer&role=trainer&type=select`;

        if (type === 'select') url = url + `&type=select`
        if (page && size) url = url + `&page=${page}&per_page=${size}`
        if (groupId) url = url + `&userGroupFiltered=${groupId}`;
        if (siteID && order_by) url += `&order_by=${order_by}`
        if (siteID && order_key) url += `&order_key=${order_key}`
        if(keyword) url += `&keyword=${keyword}`
        // dispatch({
        //     type: userConstants.FETCH_USERS_TRAINERS_REQUEST,
        // });
        dispatch({type: userConstants.FETCH_USERS_TRAINERS_REQUEST})
        client
            .get(url)
            .then(response => {
                if (status && siteID) {
                    dispatch({
                        type: userConstants.FETCH_USERS_TRAINERS_SUCCESS,
                        payload: response.data,
                    });
                } else {
                    dispatch({
                        type: userConstants.FETCH_USERS_TRAINERS_SUCCESS,
                        payload: response?.data?.payload ? response.data.payload : response.data,
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: userConstants.FETCH_USERS_TRAINERS_REJECTED,
                    payload: error,
                });
            });
    };
};
const searchTrainer = (
    page = 1,
    size = 10,
    siteID,
    query = '',
    status = '',
    keyword = '',
    groupId,
) => {
    return dispatch => {
        let url = '';

        if (siteID) {
            url = `/api/users?page=${page}&per_page=${size}&site=${siteID}&status=${status}&_role=trainer&role=trainer&keyword=${query}&userGroupFiltered=${groupId}`;
        }
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: userConstants.FETCH_USERS_TRAINERS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: userConstants.FETCH_USERS_TRAINERS_REJECTED,
                    payload: error,
                });
            });
    };
};
const fetchHRManagers = (
    page = 1,
    pageSize = 10,
    siteID,
    status,
    keyword = '',
    groupId,
) => {
    return dispatch => {
        let url = '';
        if (siteID) {
            url = `/api/users?page=${page}&per_page=${pageSize}&site=${siteID}&status=${status}&role=hrmanager&userGroupFiltered=${groupId}`;
        }
        if (keyword) url += `&keyword=${keyword}`;
        // const url = `/api/users?page=${page.toString()}&per_page=${pageSize}&filters[]=siteID=${siteID}&filters[]=role=hrmanager&type=select`;

        dispatch({
            type: userConstants.FETCH_USERS_HRMANAGERS_REQUEST,
        });
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: userConstants.FETCH_USERS_HRMANAGERS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: userConstants.FETCH_USERS_HRMANAGERS_REJECTED,
                    payload: error,
                });
            });
    };
};

function requestPasswordReset(username, email) {
    return dispatch => {
        dispatch({ type: authenticationConstants.RESET_PASSWORD_REQUEST });
        userService
            .requestPasswordReset(username, email)
            .then(() => {
                const firstChar = email.slice(0, 1);
                const myEmail = email.slice(email.indexOf('@'));
                dispatch({
                    type: authenticationConstants.RESET_PASSWORD_SUCCESS,
                    payload: `An email has been sent to your email address ${firstChar}•••••${myEmail}. follow The directions in the email to reset password.`,
                });
            })
            .catch(err => {
                dispatch({
                    type: authenticationConstants.RESET_PASSWORD_FAILURE,
                    resetPasswordError: err.data.message,
                });
            });
    };
}

function resetReducerForgot() {
    return {
        type: authenticationConstants.RESET_PASSWORD_SUCCESS,
        payload: '',
    };
}

function resetPassError() {
    return {
        type: authenticationConstants.RESET_PASSWORD_FAILURE,
        resetPasswordError: '',
    };
}

function getFirstLoginData(userID) {
    function request(loginData) {
        return { type: userConstants.FIRST_LOGIN_DATA_REQUEST, loginData };
    }

    function success(loginData) {
        return { type: userConstants.FIRST_LOGIN_DATA_SUCCESS, loginData };
    }

    return dispatch => {
        dispatch(request(firstLoginData));
        userService.getFirstLoginData(userID).then(data => {
            dispatch(success(data));
        });
    };
}

function privacyAgreed(userID) {
    return dispatch =>
        new Promise((resolve, reject) => {
            dispatch({
                type: userConstants.PRIVACY_AGREE_REQUEST,
            });
            const agreeUrl = `/api/privacy-agree/${userID}`;
            client
                .post(agreeUrl, {})
                .then(response => {
                    const user = retrieveDecryptedUserFromLocalStorage();
                    user.privacyConfirmDate = response.data.confirmDate;
                    storeEncryptedUserInLocalStorage(user);
                    dispatch({
                        type: userConstants.PRIVACY_AGREE_SUCCESS,
                    });
                    resolve();
                })
                .catch(() => {
                    dispatch({
                        type: userConstants.PRIVACY_AGREE_REJECTED,
                    });
                    reject();
                });
        });
}

function completeModules(userID) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({ type: userConstants.COMPLETE_MODULES });
            const url = `/api/users/complete-modules/${userID}`;
            client
                .get(url)
                .then(response => {
                    dispatch({
                        type: userConstants.COMPLETE_MODULES_SUCCESS,
                        payload: response.data,
                    });
                    resolve();
                })
                .catch(() => {
                    dispatch({ type: userConstants.COMPLETE_MODULES_REJECTED });
                    reject();
                });
        });
    };
}

function primarySkills(userID, langID) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({ type: userConstants.PRIMARY_SKILLS });
            const url = `/api/users/primary-skills/${userID}/${langID}`;
            client
                .get(url)
                .then(response => {
                    dispatch({
                        type: userConstants.PRIMARY_SKILLS_SUCCESS,
                        payload: response.data,
                    });
                    resolve();
                })
                .catch(() => {
                    dispatch({ type: userConstants.PRIMARY_SKILLS_REJECTED });
                    reject();
                });
        });
    };
}

// TODO: search for dispatch(userActions.search())
const search = (page = 1, pageSize = 10, filters = [], query = '') => {
    return dispatch => {
        dispatch({
            type: userConstants.USERS_SEARCH_REQUEST,
        });
        let url = '';
        if (query !== '') {
            url = `/api/users/search?q=${query}&page=${page.toString()}&per_page=${pageSize}`;
        } else {
            url = `/api/users?page=${page.toString()}&per_page=${pageSize}`;
        }
        if (filters.length > 0) {
            filters.forEach(filter => {
                const attrs = Object.getOwnPropertyNames(filter);
                attrs.forEach(attr => {
                    url += `&filters[]=${attr}=${filter[attr]}`;
                });
            });
        }
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: userConstants.USERS_SEARCH_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: userConstants.USERS_SEARCH_REJECTED,
                    payload: error,
                });
            });
    };
};

function searchUsers(params, nextPage, pageSize = 10) {
    if (cancelSearch) {
        cancelSearch();
    }
    return dispatch => {
        dispatch({
            type: userConstants.USERS_SEARCH_REQUEST,
            payload: params.keyword,
        });
        const page = nextPage || 1;
        const url = `/api/users/search?page=${page}&per_page=${pageSize}`;
        client
            .get(url, {
                params,
                cancelToken: new CancelToken(function executor(c) {
                    cancelSearch = c;
                }),
            })
            .then(response => {
                dispatch({
                    type: userConstants.USERS_SEARCH_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: userConstants.USERS_SEARCH_REJECTED,
                    payload: error,
                });
            });
    };
}

function getUser(userID, showContent = true) {
    console.log(`/api/users/${userID}`)
    return dispatch => {
        dispatch({ type: userConstants.GET_USER_REQUEST });
        const url = `/api/users/${userID}?showContent=${showContent}`;
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: userConstants.GET_USER_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: userConstants.GET_USER_REJECTED,
                    payload: error,
                });
            });
    };
}

function getUserTimeReport(userID) {
    return dispatch => {
        dispatch({ type: userConstants.GET_USER_REQUEST });
        const url = `api/users/${userID}/report/time`;
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: userConstants.GET_USER_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: userConstants.GET_USER_REJECTED,
                    payload: error,
                });
            });
    };
}
function welcomMassages(userID) {
    return dispatch => {
        dispatch({ type: userConstants.WELCOME_MASSAGES });
        const url = `/api/users/welcome-massages/${userID}`;
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: userConstants.WELCOME_MASSAGES_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: userConstants.WELCOME_MASSAGES_REJECTED,
                    payload: error,
                });
            });
    };
}

const saveUser = (data, history) => {
    return dispatch => {
        dispatch({
            type: userConstants.CREATE_USER_REQUEST,
        });
        const url = '/api/users';
        client
            .post(url, data)
            .then(response => {
                dispatch({ type: userConstants.CREATE_USER_SUCCESS });
                history.push({
                    pathname: '/admin/users',
                    successMessage: 'Successfully set up user',
                });
            })
            .catch(error => {
                notification.error({
                    message: 'Error!',
                    description: error?.statusText,
                });
                dispatch({
                    type: userConstants.CREATE_USER_REJECTED,
                    payload: error.response.data,
                });
            });
    };
};

const updateUser = (data, history, userID, user, pagePagination = null) => {
    return dispatch => {
        dispatch({
            type: userConstants.UPDATE_USER_REQUEST,
        });
        const url = `/api/users/${userID}`;
        client
            .post(url, data)
            .then(response => {
                if (response.status === 200) {
                    dispatch({ type: userConstants.UPDATE_USER_SUCCESS });
                    const historyObj = {
                        pathname: '/admin/users',
                        successMessage: `Success: User ${user.first_name} ${user.last_name} Updated`,
                    }
                    if (pagePagination) {
                        historyObj.search = `?page=${parseInt(pagePagination.page)}&pageSize=${parseInt(pagePagination.pageSize)}&orderBy=${pagePagination.orderBy}&orderKey=${pagePagination.orderKey}&siteID=${pagePagination.siteID}&userState=${pagePagination.userState}&userRole=${pagePagination.userRole}&keyword=${pagePagination.keyword}&creationDate=${pagePagination.creationDate}&endDate=${pagePagination.endDate}&selectedTrainer=${pagePagination.selectedTrainer}&selectedUserGroup=${pagePagination.selectedUserGroup}`;
                    }
                    history.push(historyObj);
                } else {
                    notification.error({
                        message: 'Error!',
                        description: error?.statusText,
                    });
                }
            })
            .catch(error => {
                notification.error({
                    message: 'Error!',
                    description: error?.statusText,
                });
                dispatch({
                    type: userConstants.UPDATE_USER_REJECTED,
                    payload: error.response.data,
                });
            });
    };
};

const updateTrainerProfile = (data, history, userID) => {
    return dispatch => {
        const url = `/api/trainer/${userID}/profile/update`
        dispatch({ type: userConstants.UPDATE_TRAINER_PROFILE_REQUEST })

        client.post(url, data)
            .then(response => {
                if (response.status === 200) {
                    dispatch({type: userConstants.UPDATE_USER_SUCCESS})
                    history.push({
                        pathname: '/dashboard',
                        successMessage: 'Successfully updated user',
                    })
                } else {
                    notification.error({
                        message: 'Error!',
                        description: error?.statusText,
                    });
                }
            }).catch(error => {
            notification.error({
                message: 'Error!',
                description: error?.statusText,
            });
            dispatch({
                type: userConstants.UPDATE_TRAINER_PROFILE_REJECTED,
                payload: error.response.data,
            });
        })
    }
}

const updateUserPassword = (data, history, userID) => {
    return dispatch => {
        const url = `api/trainer/${userID}/profile/update-password`
        dispatch({ type: userConstants.UPDATE_TRAINER_PROFILE_REQUEST })

        client.post(url, data)
            .then(response => {
                if (response.status === 200) {
                    dispatch({type: userConstants.UPDATE_USER_SUCCESS})
                    history.push({
                        pathname: '/dashboard',
                        successMessage: 'Password successfully updated',
                    })
                } else {
                    notification.error({
                        message: 'Error!',
                        description: error?.statusText,
                    });
                }
            }).catch(error => {
            notification.error({
                message: 'Error!',
                description: error?.statusText,
            });
            dispatch({
                type: userConstants.UPDATE_TRAINER_PROFILE_REJECTED,
                payload: error.response.data,
            });
        })
    }
}


const getProgressReport = (userID, start = null, end = null) => {
    return dispatch => {
        const url = `/api/users/${userID}/report/progress`;
        let data = {};
        if (start !== null && end !== null) {
            data.start = start;
            data.end = end;
        }
        dispatch({
            type: 'REPORTING_DATA_REQUEST',
        });
        client
            .post(url, data)
            .then(response => {
                dispatch({
                    type: 'REPORTING_DATA_RECEIVED',
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };
};
const updateFirstLoginUser = data => {
    return dispatch => {
        const url = `/api/users/update-first-login-user/`;
        client
            .post(url, data)
            .then(response => {
                dispatch({
                    type: userConstants.UPDATE_USER_FIRST_LOGIN_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log(error);
                notification.error({
                    message: 'Error!',
                    description: error?.statusText,
                });
            });
    };
};

const getTimeReport = (userID, start = null, end = null) => {
    return dispatch => {
        const url = `/api/users/${userID}/report/time`;
        let data = {};
        if (start !== null && end !== null) {
            data.start = start;
            data.end = end;
        }
        dispatch({
            type: 'REPORTING_DATA_REQUEST',
        });
        client
            .post(url, data)
            .then(response => {
                dispatch({
                    type: 'REPORTING_DATA_RECEIVED',
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const generateTimeReportPDF = (userID, timeReport, hideActivities) => {
    return dispatch => {
        const url = `/api/users/${userID}/report/time/export`;
        const data = {
            reportData: timeReport,
            hideActivities,
        };
        dispatch({
            type: 'REPORTING_DATA_PDF_REQUEST',
        });
        client
            .post(url, data)
            .then(response => {
                dispatch({
                    type: 'REPORTING_DATA_PDF_SUCCESS',
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const downloadTimeReportPDF = (fileName, userID, download = false) => {
    return dispatch => {
        const url = `/api/users/${userID}/report/time/download`;
        const data = {
            fileName: fileName,
        };
        dispatch({
            type: 'REPORTING_DATA_PDF_DOWNLOAD_REQUEST',
        });
        client
            .post(url, data, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], { type: 'application/pdf' }),
                );
                const link = document.createElement('a');
                link.href = url;
                if (download) {
                    link.setAttribute('download', 'file.pdf'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                } else {
                    window.open(url);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const fetchUsersByTrainer = (trainerID, start = null, end = null) => {
    return dispatch => {
        const url = `/api/trainer/${trainerID}/users`;
        const data = {};
        if (start !== null && end !== null) {
            data.start = start;
            data.end = end;
        }
        dispatch({
            type: 'REPORTING_DATA_TRAINER_USERS_REQUEST',
        });
        client
            .post(url, data)
            .then(response => {
                dispatch({
                    type: 'REPORTING_DATA_TRAINER_USERS_SUCCESS',
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const downloadUsersReportPDF = (fileName, userID, download = false) => {
    return dispatch => {
        const url = `/api/trainer/${userID}/users/download`;
        const data = {
            fileName,
        };
        dispatch({
            type: 'REPORTING_DATA_PDF_DOWNLOAD_REQUEST',
        });
        client
            .post(url, data, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], { type: 'application/pdf' }),
                );
                const link = document.createElement('a');
                link.href = url;
                if (download) {
                    link.setAttribute('download', 'file.pdf'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                } else {
                    window.open(url);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const fetchUserGroupsByTrainer = (trainerID, start = null, end = null) => {
    return dispatch => {
        const url = `/api/trainer/${trainerID}/user-groups`;
        const data = {};
        if (start !== null && end !== null) {
            data.start = start;
            data.end = end;
        }
        dispatch({
            type: 'REPORTING_DATA_TRAINER_USER_GROUPS_REQUEST',
        });
        client
            .post(url, data)
            .then(response => {
                dispatch({
                    type: 'REPORTING_DATA_TRAINER_USER_GROUPS_SUCCESS',
                    payload: response.data,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const downloadUsersReportXLS = (userID, report) => {
    return dispatch => {
        const url = `/api/trainer/${userID}/users/export-xls`;
        const data = {
            report,
        };
        dispatch({
            type: 'REPORTING_DATA_PDF_DOWNLOAD_REQUEST',
        });
        client
            .post(url, data, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type:
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    }),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const downloadUsersReportCSV = (userID, report) => {
    return dispatch => {
        const url = `/api/trainer/${userID}/users/export-csv`;
        const data = {
            report,
        };
        dispatch({
            type: 'REPORTING_DATA_PDF_DOWNLOAD_REQUEST',
        });
        client
            .post(url, data, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type: 'text/plain',
                    }),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.csv');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const downloadUsersTimeReportXLS = (userID, report, hideActivities) => {
    return dispatch => {
        const url = `/api/users/${userID}/report/time/export-xls`;
        const data = {
            report,
            hideActivities,
        };
        dispatch({
            type: 'REPORTING_DATA_PDF_DOWNLOAD_REQUEST',
        });
        client
            .post(url, data, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type:
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    }),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const downloadUsersTimeReportCSV = (userID, report, hideActivities) => {
    return dispatch => {
        const url = `/api/users/${userID}/report/time/export-csv`;
        const data = {
            report,
            hideActivities,
        };
        dispatch({
            type: 'REPORTING_DATA_PDF_DOWNLOAD_REQUEST',
        });
        client
            .post(url, data, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type: 'text/plain',
                    }),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.csv');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.log(error);
            });
    };
};

const addUsersViaCSV = (userData, history) => {
    const url = '/api/users/import/excel';

    return dispatch =>
        new Promise((resolve, reject) => {
            dispatch({
                type: userConstants.CREATE_USER_REQUEST,
            });
            client
                .post(url, userData)
                .then(response => {
                    // dispatch({ type: userConstants.CREATE_USER_SUCCESS });
                    resolve(response);

                    // notification.success({
                    //     message: 'Success',
                    //     description: 'Successfully imported users',
                    // });
                    // history.push('/admin/users');
                })
                .catch(error => {
                    reject(error);
                });
        });
};

const clearUser = (userData, history) => {
    return dispatch =>
        dispatch({
            type: userConstants.CLEAR_USER,
        });
};

function requestPasswordResetV2(username, email, newPassword, confirmPassword, token, history) {
    return dispatch => {
        dispatch({ type: authenticationConstants.RESET_PASSWORD_REQUEST_V2 });
        userService
            .requestPasswordResetV2(username, email, newPassword, confirmPassword, token )
            .then(() => {
                dispatch({
                    type: authenticationConstants.RESET_PASSWORD_SUCCESS_V2,
                });
                history.push('/login');
            })
            .catch(err => {
                dispatch({
                    type: authenticationConstants.RESET_PASSWORD_FAILURE_V2,
                    resetPasswordErrorV2: err.data.message,
                });
            });
    };
}

function dontShowDashboardMessageToUser() {
    const url = '/api/user/disable-dashboard-message';

    return (dispatch) =>
        new Promise((resolve, reject) => {
            client
                .post(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}
export const userActions = {
    fetchUserTrainers,
    clearUser,
    fetchAllUsers,
    login,
    logout,
    fetchUsers,
    fetchLearners,
    fetchTrainers,
    searchTrainer,
    fetchHRManagers,
    requestPasswordReset,
    requestPasswordResetV2,
    getFirstLoginData,
    search,
    searchUsers,
    getUser,
    getUserTimeReport,
    saveUser,
    updateUser,
    updateTrainerProfile,
    updateUserPassword,
    privacyAgreed,
    welcomMassages,
    getProgressReport,
    getTimeReport,
    generateTimeReportPDF,
    downloadTimeReportPDF,
    downloadUsersReportPDF,
    fetchUsersByTrainer,
    fetchUserGroupsByTrainer,
    downloadUsersReportCSV,
    downloadUsersReportXLS,
    downloadUsersTimeReportCSV,
    downloadUsersTimeReportXLS,
    addUsersViaCSV,
    resetError,
    resetPassError,
    resetReducerForgot,
    completeModules,
    primarySkills,
    updateFirstLoginUser,
    dontShowDashboardMessageToUser,
};
